import { Router } from "@reach/router";
import "react-notion/src/styles.css";
import Post from "./components/post";

function App() {
  return (
    <Router>
      <Post path="/posts/:id" />
    </Router>
  );
}

export default App;
