import React, { useEffect, useState } from "react";
import "react-notion/src/styles.css";
import { NotionRenderer } from "react-notion";

const Post = ({ id }) => {
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(
        `https://notion-test-api.jcorreiavale.workers.dev/v1/page/${id}`
      );
      console.log(resp);
      const postResp = await resp.json();
      setPost(postResp);
    };

    getPost();
  }, [id]);
  console.log("post", post);

  if (!Object.keys(post).length) return <div />;

  return <NotionRenderer blockMap={post} fullPage />;
};

export default Post;
